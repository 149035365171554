import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/account";
import app from "./modules/app";
import i18n from "@/helpers/i18n";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appSettings: {
      projectName: i18n.t("appName"),
      tokenTime: 1,
    },
  },
  getters: {
    getAppSettings: (state) => {
      return state.appSettings;
    },
  },
  mutations: {},
  actions: {},
  modules: {
    app,
    account,
  },
});
