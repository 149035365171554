<template>
  <div class="page-loading">
    <span class="loader primary-loading small-loader"></span>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
