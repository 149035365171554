// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{params: {id: string}, query: {}}} option
 *
 * @returns
 */
export const Delete = async (option) => {
  const response = await $api.delete(Endpoints.User.Delete(option));
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  Delete,
};
