import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/CompanyUser";

export const CreateUser = (options) =>
  GENERATE_URL(`${PREFIX_URL}/ValidateUser`, options);

export default {
  CreateUser,
};
