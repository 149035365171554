// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{params: object, query: { "filter[companyId]": string, "filter[customerId]": "expand" || "sub" }}} option
 *
 * @returns
 */
export const GetCustomerPackageByCompanyId = async (option) => {
  const response = await $api.get(
    Endpoints.CustomerPackage.GetCustomerPackageByCompanyId(option)
  );
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  GetCustomerPackageByCompanyId,
};
