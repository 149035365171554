import i18n from "@/helpers/i18n";

const message = {
  OK: "OK",
  NOTFOUND: "NOTFOUND",
  NOT_FOUND: "NOT_FOUND",
  ERRORLOGIN: "ERRORLOGIN",
  LOGINERROR: "LOGINERROR",
  MODEL_ERROR: "MODEL_ERROR",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  ENTITY_ERROR: "ENTITY_ERROR",
  ALREADY_EXIST: "ALREADY_EXIST",
};

const defaultMessages = [
  {
    controls: [
      message.OK,
      message.NOTFOUND,
      message.NOT_FOUND,
      message.ALREADY_EXIST,
    ],
    message: {
      text: i18n.t("message.OK.description"),
      status: true,
      variant: "success",
      notification: true,
    },
  }, // işlem başarılı olduğunda yapılan kontrol
  {
    controls: [
      message.MODEL_ERROR,
      message.ENTITY_ERROR,
      message.INTERNAL_SERVER_ERROR,
    ],
    message: {
      text: i18n.t("message.INTERNAL_SERVER_ERROR.description"),
      status: true,
      variant: "danger",
      notification: true,
    },
  }, // şifre veya email doğru değil ise.
];

/**
 * @ {string} OK - İşlem başarılı olduğunda döner.
 * @param {string} NOTFOUND - Yapılan işlemde data bulunamaz ise döner.
 * @param {string} ERRORLOGIN - Giriş yapmak isteyen kullanıcı için hesap bilgileri yanlış olduğunda döner.
 * @param {string} LOGINERROR - Giriş yapmak isteyen kullanıcı için hesap bilgileri yanlış olduğunda döner.
 */
export { message, defaultMessages };
