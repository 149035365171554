// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{"fullname": string,"phoneNo": string,"areaCode": "string", password: "string", token: "string"}} form
 * @param {{params: object, query: {}}} option
 *
 * @returns
 */
export const CreateUser = async (form, option) => {
  const response = await $api.post(
    Endpoints.CompanyUser.CreateUser(option),
    form
  );
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  CreateUser,
};
