<template>
  <Modal persistent hide-header hide-footer sm v-model="getActionModal.status">
    <div class="c-action-modal">
      <div v-if="getActionModal.isLoading">
        <span class="loader primary-loading"></span>
      </div>
      <div
        class="c-action-modal__icon--success"
        v-else-if="getActionModal.variant === 'success'"
      >
        <i class="icofont-check-circled"></i>
      </div>
      <div
        class="c-action-modal__icon--danger"
        v-else-if="getActionModal.variant === 'danger'"
      >
        <i class="icofont-close-circled"></i>
      </div>
      <div
        class="c-action-modal__icon--info"
        v-else-if="getActionModal.variant === 'info'"
      >
        <i class="icofont-info-circle"></i>
      </div>
      <div
        v-if="getActionModal.isLoading"
        class="action-modal-message u-margin-top-small u-text-align-center"
      >
        {{ $t("actionModal.isLoading") }}
        <div>
          <small class="u-color-muted">
            {{ $t("actionModal.pleaseWait") }}
          </small>
        </div>
      </div>
      <div v-else class="action-modal-message u-text-align-center">
        {{ getActionModal.message }}
      </div>
      <div
        v-if="!getActionModal.isLoading"
        class="c-action-modal__caption-text"
      >
        {{
          $t("actionModal.caption", {
            second: getActionModal.duration / 1000 || 4,
          })
        }}
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";
import Modal from "@base/Modal";
export default {
  components: {
    Modal,
  },
  computed: {
    ...mapGetters(["getActionModal"]),
  },
};
</script>

<style></style>
