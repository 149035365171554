import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@utils/directives.js";
import Tooltip from "./utils/TooltipDirective";
import dateClass from "./helpers/date";
Vue.use(Tooltip);
// dil seçenekleri.
import i18n from "@/helpers/i18n.js";

const date = new dateClass(i18n.locale);

Vue.prototype.$date = date;

//select box
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

// Use v-calendar & v-date-picker components
import VCalendar from "v-calendar";
Vue.use(VCalendar);

//loadings
import Loading from "@/components/Base/Loading/Loading.vue";
import PageLoading from "@/components/Base/Loading/PageLoading.vue";

Vue.component("Loading", Loading);
Vue.component("PageLoading", PageLoading);

import "@/assets/scss/main.scss";

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
