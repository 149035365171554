import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/Reservation";

export const GetActiveTimesByDay = (options) =>
  GENERATE_URL(`${PREFIX_URL}/GetActiveTimesByDay`, options);

export const Create = (options) =>
  GENERATE_URL(`${PREFIX_URL}/QuickReservation`, options);

export const GetById = (options) =>
  GENERATE_URL(`${PREFIX_URL}/:id`, options);
export default {
  GetActiveTimesByDay,
  Create,
  GetById
};
