// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{"fullname": string,"phoneNo": string,"companyId": string}} form
 * @param {{params: object, query: { "filter[companyId]": string }}} option
 *
 * @returns
 */
export const CreateContact = async (form, option) => {
  const response = await $api.post(
    Endpoints.Customer.CreateContact(option),
    form
  );
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  CreateContact,
};
