import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/CustomerPackage";

export const GetCustomerPackageByCompanyId = (options) =>
  GENERATE_URL(`${PREFIX_URL}`, options);

export default {
  GetCustomerPackageByCompanyId,
};
