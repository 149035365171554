// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{params: object, query: { "filter[companyId]": string }}} option
 *
 * @returns
 */
export const GetRoomByCompanyId = async (option) => {
  const response = await $api.get(Endpoints.Room.GetRoomByCompanyId(option));
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};
// ## Bank Account Details
/**
 *
 * @param {{params: { id: string }, query: { includes: string }}} option
 *
 * @returns
 */
export const GetByIdWithIncludes = async (option) => {
  const response = await $api.get(Endpoints.Room.GetByIdWithIncludes(option));
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  GetRoomByCompanyId,
  GetByIdWithIncludes
};
