// import { GENERATE_URL } from "./helpers/request.js";
import Company from "./Company";
import Room from "./Room";
import CompanyService from "./CompanyService";
import Validation from "./Validation";
import CustomerPackage from "./CustomerPackage";
import Reservation from "./Reservation";
import Customer from "./Customer";
import CompanyUser from "./CompanyUser";
import Auth from "./Auth";
import User from "./User";
export default {
  Company,
  CustomerPackage,
  Customer,
  Validation,
  CompanyService,
  Reservation,
  Room,
  Auth,
  CompanyUser,
  User,
};
