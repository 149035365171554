import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/Validation";

export const SendValidationCode = (options) =>
  GENERATE_URL(`${PREFIX_URL}/SendValidationCode`, options);

export const ValidationCode = (options) =>
  GENERATE_URL(`${PREFIX_URL}/ValidationCode`, options);

export default {
  SendValidationCode,
  ValidationCode,
};
