<template>
  <button
    :disabled="disabled || loadingData || loading"
    :type="type"
    @submit="(e) => $emit('submit', e, SET_LOADING)"
    @click="(e) => $emit('click', e, SET_LOADING)"
    :class="`${variantComputed} ${blockComputed} ${sizeComputed} ${groupItemComputed}`"
  >
    <span v-if="icon">
      <i :class="`icofont-${icon} ${!hasSlot ? 'u-font-size-medium' : ''}`"></i>
    </span>
    <div v-if="loadingData || loading" class="loader-section">
      <span class="loader button"></span>
    </div>
    <span v-if="hasSlot" :class="{ 'u-margin-left-xsmall': icon }"
      ><slot></slot
    ></span>
  </button>
</template>

<script>
export default {
  data() {
    return {
      loadingData: false,
      hasSlot: false,
    };
  },
  props: {
    type: {
      default: "button",
    },
    variant: {
      default: "",
    },
    icon: {
      default: "",
    },
    size: {
      default: "",
    },
    groupItem: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    variantComputed() {
      return this.variant ? `btn-${this.variant}` : "";
    },
    blockComputed() {
      return this.block ? `btn-block` : "";
    },
    sizeComputed() {
      return this.size !== "" ? `btn-${this.size}` : "";
    },
    groupItemComputed() {
      return this.groupItem ? `btn-group` : "";
    },
  },
  methods: {
    SET_LOADING(loading = true) {
      this.loadingData = loading;
    },
  },
  mounted() {
    if (this.$scopedSlots.default) {
      this.hasSlot = true;
    }
  },
};
</script>

<style></style>
