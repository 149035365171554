// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{params: object, query: { "filter[slug]": string }}} option
 *
 * @returns
 */
export const GetCompanyIdBySlug = async (option) => {
  const response = await $api.get(Endpoints.Company.GetCompanyIdBySlug(option));
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  GetCompanyIdBySlug,
};
