import router from "@router/index";
import crypto from "@utils/crypto";
import $api from "@services/Api";
import Settings from "../../settings.json";
import Requests from "@/services/Requests";
const state = {
  status: "unitiliazed",
  activeUser: null,
};
const getters = {
  getActiveUser: (state) => state.activeUser?.data,
};
const mutations = {
  SET_STATUS(state, payload) {
    console.log("status: ", payload);
    state.status = payload || "unitiliazed";
  },
  // Uygulamada active olan user bilgisi set edilir.
  SET_ACTIVE_USER(state, payload) {
    state.activeUser = payload;
    if (payload.data) $api.token = payload.data.token;
  },
};

const actions = {
  // kullanıcı uygulamaya giriş yapar.
  async TO_SIGN_IN({ commit }, form) {
    commit("SET_STATUS", "loading");
    const response = { message: "OK", data: {} }; //await REQUEST_TO_SIGN_IN(form);
    if (response.message === "OK") {
      commit("SET_ACTIVE_USER", response);
      localStorage.setItem("user", crypto.crypto(JSON.stringify(response))); // user bilgisi set edilir.
      localStorage.setItem("email", form.username); // user bilgisi set edilir.
      const time =
        new Date().getTime() + Settings.tokenTime * 24 * 60 * 60 * 1000; // ${settings.tokenTime} günlük eat verilir.
      localStorage.setItem("eat", crypto.crypto(String(time))); // eat bilgisi set edilir.
      setTimeout(() => {
        // tüm işlemler başarılı ise kullanıcı site başlangıç sayfasına yönlendirilir.
        router.push({ name: "app-start" });
        commit("SET_STATUS", "unitiliazed");
      }, 500);
    } else commit("SET_STATUS", "unitiliazed");

    return response;
  },
  async TO_FORGET_PASSWORD({ commit }, form) {
    commit("SET_STATUS", "loading");
    const response = await Requests.Admin.forgetPassword(form);
    console.log("response ,", response);

    if (response.message === "OK") {
      commit("SET_AUTH_MESSAGE", {
        status: true,
        message: response.message,
        variant: "success",
        notification: true,
      });
    } else commit("SET_STATUS", "unitiliazed");

    return response;
  },
  // kullanıcı uygulamadan çıkış yapar.
  SIGNOUT_USER({ commit }, { variant = "danger", message }) {
    commit("SET_AUTH_MESSAGE", { status: true, message, variant }); // Hata alındığında çalıştırıp kullanıcıyı bilgilendiren fonksiyon.
    localStorage.removeItem("user"); // user bilgisi silinir.
    localStorage.removeItem("eat"); // eat bilgisi silinir.
    $api.token = ""; // $api içersindeki token temizlenir.
    const $route = router.app.$route;

    if ($route.meta.layout !== "account") router.push({ name: "sign-in" }); // kullanıcı logine gönderilir.
  },
};

export default { state, getters, mutations, actions };
