// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{params: object, query: { "filter[roomId]": string , "filter[reservationDate]": Date }}} option
 *
 * @returns
 */
export const GetActiveTimesByDay = async (option) => {
  const response = await $api.get(
    Endpoints.Reservation.GetActiveTimesByDay(option)
  );
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

// ## Bank Account Details
/**
 *
 * @param {{"customerId": "string","roomId": "string","companyId": "string","companyServiceIds": Array<string>,"reservationDate": Date,"reservationTime": Date,"customerPackageId": string | null}} form
 * @param {{params: object, query: object}} option
 *
 * @returns
 */
export const Create = async (form, option) => {
  const response = await $api.post(Endpoints.Reservation.Create(option), form);
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

// ## Bank Account Details
/**
 *
 * @param {{params: object, query: { "id": string }}} option
 *
 * @returns
 */
export const GetById = async (option) => {
  const response = await $api.get(Endpoints.Reservation.GetById(option));
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  GetActiveTimesByDay,
  Create,
  GetById,
};
