import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/Auth";

export const ResetPassword = (options) =>
  GENERATE_URL(`${PREFIX_URL}/ResetPassword`, options);

export const UserLogin = (options) =>
  GENERATE_URL(`${PREFIX_URL}/UserLogin`, options);

export default {
  ResetPassword,
  UserLogin,
};
