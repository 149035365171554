import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/Customer";

export const CreateContact = (options) =>
  GENERATE_URL(`${PREFIX_URL}/CreateContact`, options);

export default {
  CreateContact,
};
