<template>
  <div id="app">
    <div id="Notifications"></div>
    <router-view />
    <ActionModal />
  </div>
</template>

<script>
import ActionModal from "@/components/Base/ActionModal";
export default {
  components: { ActionModal },
  data() {
    return {
      internetStatus: {
        connection: false,
        speed: 10,
      },
    };
  },
  methods: {
    GET_LANG() {
      const lang = localStorage.getItem("lang");
      if (this.$i18n.availableLocales.includes(lang)) this.$i18n.locale = lang;
    },
  },
  async created() {
    // dil bilgisi alınır.
    this.GET_LANG();
    //this.CONNECTION_INFORMATION_OF_INTERNET();
  },
};
</script>

<style></style>
