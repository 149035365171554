// atılan istekten dönülecek cevabı döndüren function'dır.
//  parameters:
//    1. arr: İstekte dönülecek verileri sağlar. RESPONSE_CHECK_ENDPOINT'den gelen key ile dönen cevabın value'su object içindeki controls arrayinden bulunur.Bulunan value ile cevap return eder.
//    2. response: Cevaptan dönen response'dur.

// result kontrolü client kontrolüdür. istek cevapları errorStatus harici dönüşlerde fail olarak gelecektir. Bu durumlarda modal açılır.

import store from "@/store";
import i18n from "@helpers/i18n.js";
import Response from "../Response";
import Notification from "../helpers/toast";
/**
 * @param {Array.<{meta?: object, controls: {string}[], message: { text: string, status: boolean,notification: boolean, variant: 'success'|'danger'|'warning'|'info'} }>} arr - Response içinde kontrol edilecek array.
 * @param {{message: string, isSuccess: boolean, data?: object}} response - Servisten dönen cevaptır. (async)
 * @returns {Promise<{ message: string , isSuccess: boolean, data?: <T>, notification: {meta?: object, controls: {string}[], text: string, status: boolean, notification: boolean, variant: 'success'|'danger'|'warning'|'info'}}>} - Response checker dönen cevap.
 */
export default (arr, response) => {
  if (!ResponseChecker(response)) {
    console.log("hererherherehe");
    
    IF_FAIL_RESPONSE(response); // check durumu fail gelirse dönülür.
    return { message: "FAIL", isSuccess: false, notification: {} };
  }
  const message = response.message; // dönüş kontrol bilgisi endpoint ile alınır.
  const reqResponseObject = arr.find((item) => item.controls.includes(message)); // istekteki cevap bulunur.
  console.log("buarada mı ? ", reqResponseObject);
  if (reqResponseObject) {
    if (
      reqResponseObject.message.notification &&
      (typeof response.data !== "object" ||
        reqResponseObject.message.variant !== "success")
    )
      Notification(
        i18n.t(`notification.${reqResponseObject.message.variant}`),
        reqResponseObject.message.text,
        reqResponseObject.message.variant
      );
    return { checker: reqResponseObject, ...response }; // objenin içerisindeki mesaj objesini dön.
  }
  IF_FAIL_RESPONSE(response); // eğer object bulunamazsa.
  return { message: "FAIL", isSuccess: false, notification: {} };
};

//  eğer cevap fail dönerse döndürülecek mesaj.
const IF_FAIL_RESPONSE = (response) =>
  Response(response, i18n.t("authMessages.somethingWentWrong"), true, "danger");

const ResponseChecker = (response) => {
  if (response.result === "OK") return true;
  store.dispatch("SET_ACTION_MODAL", {
    isLoading: false,
    status: true,
    message: i18n.t("authMessages.somethingWentWrong"),
    variant: "danger",
    duration: 10000,
  });
  return false;
};
