// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

// ## Bank Account Details
/**
 *
 * @param {{"phoneNo":string, "companyId":string}} form
 * @param {{params: object, query: { "filter[slug]": string }}} option
 *
 * @returns
 */
export const SendValidationCode = async (form, option) => {
  const response = await $api.post(
    Endpoints.Validation.SendValidationCode(option),
    form
  );
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

// ## Bank Account Details
/**
 *
 * @param {{"phoneNo":string, "companyId":string, "code":string}} form
 * @param {{params: object, query: { "filter[slug]": string }}} option
 *
 * @returns
 */
export const ValidationCode = async (form, option) => {
  const response = await $api.post(
    Endpoints.Validation.ValidationCode(option),
    form
  );
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  SendValidationCode,
  ValidationCode,
};
