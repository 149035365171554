import { capitalizeFirstLetter } from "@utils/textTransform";

export function JSON_TO_FORM_DATA(form) {
  const fd = new FormData();
  for (const key in form) {
    if (Object.hasOwnProperty.call(form, key)) {
      const element = form[key];
      fd.append(capitalizeFirstLetter(key), element);
    }
  }
  return fd;
}

/**
 *
 * @param {string} url - BASE_URL + {url} olarak kullanılır.
 * @param {{ params: object, query: object}} options - Endpoint içerisindeki params ve query'i set eder. y.com/{...params}?{...query}
 * @returns {{url: string}} - ${BASE_URL} + ${url} döner.
 */
export function GENERATE_URL(url, options = { params: {}, query: {} }) {
  // set query for url;
  let q = new URLSearchParams();
  if (options.query) {
    for (const key in options.query) {
      if (Object.hasOwnProperty.call(options.query, key)) {
        const element = options.query[key];
        if (element.length && typeof element === "object") {
          for (let i = 0; i < element.length; i++) {
            q.append(key + "[]", element[i]);
          }
        } else q.append(key, element);
      }
    }
  }

  if (options.query) q = "?" + q;
  // set params, if there are params.
  let uri = url;
  for (const key in options.params) {
    if (Object.hasOwnProperty.call(options.params, key)) {
      const element = options.params[key];
      uri = uri.replace(`:${key}`, element);
    }
  }
  return `${uri}${q}`;
}
