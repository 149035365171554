const defaultObj = {
  fadeTime: 400,
  fadeTicks: 50,
  fadeInterval: 0,
  opacity: 1,
  time: 2,
  ticks: 500,
  element: null,
  progress: null,
  progressPos: 0,
  progressIncrement: 0,
  Show: () => false,
};

/**
 *
 * @param {string} Title - Notification başlığı,
 * @param {string} Message - Notification body'si
 * @param {'success'|'danger'|'info'|'warning'} Variant - Notification body'si
 * @param {string} Icon - icon
 * @param {{fadeTime: string, time: string}} settings - Ayarlar
 * @returns
 */
function Notification(Title, Message, Variant, Icon, settings = {}) {
  var obj = {
    ...defaultObj,
    ...settings,
  };
  obj.Show = function () {
    obj.element = document.createElement("div");
    obj.element.className = "Notification " + Variant;
    const content = document.createElement("div");
    content.className = "Content";
    content.innerHTML = `
    <h1> <div> <i class="${Icon}"></i> ${Title}</div> <div> <i class="icofont-close"></i> </div> </h1>
    <p class="u-margin-top-small"><span> ${Message} </span></p>`
    obj.element.appendChild(content);
    var progressDiv = document.createElement("div");
    progressDiv.className = "ProgressDiv";
    obj.progress = document.createElement("div");
    progressDiv.appendChild(obj.progress);
    obj.element.appendChild(progressDiv);
    obj.progressIncrement = 100 / obj.ticks;
    document.getElementById("Notifications").appendChild(obj.element);
    obj.StartWait();
  };
  obj.StartWait = function () {
    if (obj.progressPos >= 100) {
      obj.fadeInterval = 1;
      obj.FadeTick();
      return;
    }
    setTimeout(obj.Tick, obj.time);
  };
  obj.Clear = function () {
    obj.opacity = 0;
    obj.progressPos = 100;
    obj.element.remove();
    obj = null;
    return;
  };
  obj.FadeTick = function () {
    obj.opacity = (obj.opacity * 100 - obj.fadeInterval) / 100;
    if (obj.opacity <= 0) {
      obj.element.remove();
      obj = null;
      return;
    }
    obj.element.style.opacity = obj.opacity;
    setTimeout(obj.FadeTick, obj.fadeTime / obj.fadeTicks);
  };
  obj.Tick = function () {
    obj.progressPos += obj.progressIncrement;
    obj.progress.style.width = obj.progressPos + "%";
    obj.StartWait();
  };
  obj.Show();
  return obj;
}

export default Notification;
