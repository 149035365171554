import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/CompanyService";

export const GetCompanyServiceByCompanyId = (options) =>
  GENERATE_URL(`${PREFIX_URL}`, options);

export default {
  GetCompanyServiceByCompanyId,
};
