import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@helpers/i18n.js";

import Settings from "../settings.json";

Vue.use(VueRouter);

// services
// import AutoLogin from "./services/AutoLogin";

const routes = [
  {
    name: "app-start",
    path: "/",
    redirect: "/",
    component: () =>
      import(/* webpackChunkName: "app-start" */ "../layouts/default.vue"),
    children: [
      {
        name: "home",
        path: "",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "chat" */ "../views/Chat.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug/:roomId",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "chat-1" */ "../views/Chat.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug/:roomId/:serviceIds",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "chat-2" */ "../views/Chat.vue"),
      },
      {
        name: "chat",
        path: "chat/:slug/:roomId",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "chat-3" */ "../views/Chat.vue"),
      },
      {
        name: "track",
        path: "track/:id",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "track" */ "../views/Track.vue"),
      },
      {
        name: "create-user",
        path: "create-user",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "create-user" */ "../views/CreateUser.vue"
          ),
      },
      {
        name: "reset-password",
        path: "reset-password",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
          ),
      },
      {
        name: "privacy-policy",
        path: "privacy-policy",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "privacy-policy" */ "../views/privacy-policy.vue"
          ),
      },
      {
        name: "download",
        path: "download",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "download" */ "../views/download.vue"),
      },
      {
        name: "login",
        path: "login",
        meta: {
          layout: "default",
          auth: true,
        },
        component: () =>
          import(/* webpackChunkName: "login" */ "../views/Account.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

router.beforeEach((to, from, next) => {
  document.title =
    i18n.t(`navigation.${to.name}`) + " | " + Settings.projectName;
  next();
});
export default router;
