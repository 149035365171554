<template>
  <div
    class="u-margin-top u-margin-bottom u-display-flex u-width--100% u-justify-content-center u-align-items-center"
  >
    <span class="loader primary-loading small-loader"></span>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style></style>
