// -------------------------------------------- IMPORT FILES START --------------------------------------------------------------
// ## Import Files
import $api from "../Api";
import ResponseChecker from "../Middlewares/ResponseChecker";
import { defaultMessages, message } from "../enums/responseMessages.js";

import Endpoints from "../Endpoints";
import i18n from "@/helpers/i18n";
// -------------------------------------------- IMPORT FILES ENDED --------------------------------------------------------------

const ResetPasswordMessages = [
  {
    controls: [message.OK, message.NOT_FOUND],
    message: {
      text: i18n.t("message.OK.description"),
      status: true,
      variant: "success",
      notification: true,
    },
  }, // işlem başarılı olduğunda yapılan kontrol
];

// ## Bank Account Details
/**
 *
 * @param {{password: "string", token: "string"}} form
 * @param {{params: object, query: {}}} option
 *
 * @returns
 */
export const ResetPassword = async (form, option) => {
  const response = await $api.post(Endpoints.Auth.ResetPassword(option), form);
  const checker = await ResponseChecker(ResetPasswordMessages, response);

  return checker;
};

// ## Bank Account Details
/**
 *
 * @param {{password: "string", token: "string"}} form
 * @param {{params: object, query: {}}} option
 *
 * @returns
 */
export const UserLogin = async (form, option) => {
  const response = await $api.post(Endpoints.Auth.UserLogin(option), form);
  if (response.message === "OK") $api.token = response.data.token;
  const checker = await ResponseChecker(defaultMessages, response);

  return checker;
};

export default {
  ResetPassword,
  UserLogin,
};
