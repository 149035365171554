import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/Room";

export const GetRoomByCompanyId = (options) =>
  GENERATE_URL(`${PREFIX_URL}`, options);
export const GetByIdWithIncludes = (options) =>
  GENERATE_URL(`${PREFIX_URL}/:id`, options);

export default {
  GetByIdWithIncludes,
  GetRoomByCompanyId,
};
