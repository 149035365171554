import { GENERATE_URL } from "@services/helpers/request";

const PREFIX_URL = "/Company";

export const GetCompanyIdBySlug = (options) =>
  GENERATE_URL(`${PREFIX_URL}/GetCompanyIdBySlug`, options);

export default {
  GetCompanyIdBySlug,
};
